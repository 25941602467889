
export default function () {
    const passwordContainer = '.m-password';
    const passwordValue = '.m-password__value';
    const iconClass = "m-password__icon";
    const iconClassActive = iconClass + '--active';

    $('.' + iconClass).on('click', function () {
        if (!$(this).hasClass(iconClassActive)) {
            let $icon = $(this);
            $('.' + iconClass).removeClass(iconClassActive);

            $(passwordValue).text("********")
            var $passwordElement = $icon.parent($(passwordContainer)).find(passwordValue)

            $passwordElement.text($passwordElement.attr("value"))
            $icon.addClass(iconClassActive)
        }
        else {
            $('.' + iconClass).removeClass(iconClassActive);
            $(passwordValue).text("********")
        }
    });
}